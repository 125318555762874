export function initPostMessageListeners() {
  window.addEventListener('message', (event) => {
    if (event && event.data && typeof event.data === 'object' && event.data.message != "") {
      if ('isStaging' in event.data) {
        if(event.data.isStaging) {
          document.body.classList.add('display-staging');   
        }
        else {
          document.body.classList.remove('display-staging');
        }
      }
      if ('origin' in event.data) {
        ANSWERS.core.storage.storage.set('origin', event.data.origin);
      }
    }
  }, false);

  updateURLOnPageLoad();
}

function updateURLOnPageLoad() {
  let updateURL = window.setInterval(()=>{
    if (ANSWERS.core.storage.storage.get('origin')) {
      let links = document.querySelectorAll('.AdvisorList-cta');
      for (let i = 0; i < links.length; i++) {
        if (links[i].href.indexOf('origin=') == -1){
          links[i].href = links[i].href + '&origin=' + ANSWERS.core.storage.storage.get('origin');
        }
      }
      clearInterval(updateURL);
    }
  }, 500);

  window.setTimeout(()=>{
    clearInterval(updateURL);
  }, 5000);
}