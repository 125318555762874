export function initPostMessageListeners() {
  window.addEventListener('message', (event) => {
    if (event && event.data && typeof event.data === 'object' && event.data.message != "") {
      if ('isStaging' in event.data) {
        if(event.data.isStaging) {
          document.body.classList.add('display-staging');   
        }
        else {
          document.body.classList.remove('display-staging');
        }
      }
      if ('origin' in event.data) {
        ANSWERS.core.storage.storage.set('origin', event.data.origin);
      }
    }
  }, false);

  updateURLOnPageLoad();
}

function updateURLOnPageLoad() {
  let updateURL = window.setInterval(()=>{
    if (ANSWERS.core.storage.storage.get('origin')) {
      let links = document.querySelectorAll('.AdvisorList-cta');
      for (let i = 0; i < links.length; i++) {
        if (links[i].href.indexOf('origin=') == -1){
          links[i].href = links[i].href + '&origin=' + ANSWERS.core.storage.storage.get('origin');
        }
      }
      clearInterval(updateURL);
    }
  }, 500);

  window.setTimeout(()=>{
    clearInterval(updateURL);
  }, 5000);
}

export async function mapAdvisorsToBranches() {
  var results = await getAllResults();
  var advisorsByBranch = {};
  for (let i = 0; i < results.length; i++) {
    const entity = results[i];
    if (entity.data.c_linkedAffiliatePCRMAdvisor) {
      entity.data.c_linkedAffiliatePCRMAdvisor.forEach((branch, j) => {
        if (advisorsByBranch[branch.id]) {
          advisorsByBranch[branch.id].push(entity.data);
        } else {
          advisorsByBranch[branch.id] = [entity.data];
        }
      });
    }
  }  
  ANSWERS.core.storage.set('advisorsByBranch', advisorsByBranch);
}

export async function getAllResults() {
  var offset=0;
  const limit = 50;
  var allResults = [];
  var pageResults = await getPageResults(offset, limit);

  while (pageResults.length > 0) {
    offset += limit;
    allResults = allResults.concat(pageResults);
    pageResults = await getPageResults(offset, limit);

  }
  return allResults;
}

async function getPageResults(offset, limit){
  const sessionID = window.sessionStorage.getItem('sessionId');
  const verticalKey = ANSWERS.core.storage.get('search-config')?.verticalKey;
  const apiKey = '1919349b1689c56ff11fedc59f641065';
  const experienceKey = 'citizens-wealth-pcrm'
  const v = '20220511'
  const version = 'STAGING' //TODO: 'change to PRODUCTION before committing'
  var input = ANSWERS.core.storage.get('query');
  input = input ? encodeURIComponent(input) : '';
  var filters= ANSWERS.core.storage.get('filters') ? encodeURIComponent(JSON.stringify(ANSWERS.core.storage.get('filters'))) : '';
  if (!(sessionID && verticalKey && version && input)) {
    console.log("Missing required data for result fetch");
    return [];
  }

  var url = `https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?experienceKey=${experienceKey}&api_key=${apiKey}&v=${v}&version=${version}&input=${input}&verticalKey=advisors&limit=${limit}&offset=${offset}&session_id=${sessionID}`
  if (filters) {
    url += `&filters=${filters}`;
  }
  const response = await fetch(url);
  const data = await response.json();
  if (data.meta && data.meta && data.meta.errors.length === 0 && data?.response?.results?.length > 0) {
    return data.response.results;
  }
  return [];
}